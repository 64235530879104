<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{on,attrs}">
      <v-btn
        icon
        dark
        v-bind="attrs"
        v-on="on"
        @click="loadUsuario()"
      >   
        <v-icon v-if="currentItem.id" medium class="mr-2" color="grey">mdi-account-edit-outline</v-icon>
        <v-icon v-else large class="mr-2" color="grey">mdi-plus-circle</v-icon>
      </v-btn>
    </template>
    
    <v-card class="mx-auto">
      <v-card-title>
        <v-row>
        <v-col cols="11">Manutenção</v-col>
        <v-col cols="1"><v-btn icon @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn></v-col>
      </v-row>
          
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab>Dados de acesso</v-tab>
          <v-tab v-show="pessoa.id">Dados Pessoais</v-tab>
          <v-tab v-show="pessoa.id">Endereços</v-tab>
          
          <v-tab v-show="pessoa.id">Dados Bancários</v-tab>
          <v-tab v-show="pessoa.id">Permissões</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">  
          <v-tab-item>
            <DadosAcesso @tab_emit="tab_emit"/>
          </v-tab-item>
          <v-tab-item v-show="pessoa.id">
            <DadosPessoais />
          </v-tab-item>
          <v-tab-item v-show="pessoa.id">
            <Enderecos />
          </v-tab-item>
          <v-tab-item v-show="pessoa.id">
            <DadosBancarios />
          </v-tab-item>
          <v-tab-item v-show="pessoa.id">
            <Permissoes />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  name:"Tab",
  components:{
    DadosAcesso: () => import("./DadosAcesso.vue"),
    DadosPessoais: () => import("./DadosPessoais"),
    Enderecos: () => import("./Enderecos.vue"),
    DadosBancarios: () => import("./DadosBancarios.vue"),
    Permissoes: () => import("./Permissoes.vue"),
},
  props: {  
    currentItem: {  
      type: Object
    }
  },
  data:()=>({
    tab:0,
    dialog:false,
  }),
  methods:{
    tab_emit(val){
      this.tab=val
    },
    loadUsuario(){
      this.$store.dispatch('pessoa',this.currentItem.id? this.currentItem:{id:null})
    }
  },
  computed:{
    ...mapGetters(['pessoa'])
  },
  mounted(){
  
  }
}
</script>